function Home() {
    return (
      <div className="App">      
        <h1> Asian baby girls </h1>      
        <h2> ABG of the months </h2>
        <ul> 
          <li>
          January
          Instagram: @kathyyymm
          </li>

          <li>
          Febuary
          Instagram: @kathyyymm
          </li>

          <li>
          March
          Instagram: @kathyyymm
          </li>

          <li>
          April
          Instagram: @kathyyymm
          </li>

          <li>
          May
          Instagram: @kathyyymm
          </li>

          <li>
          June
          Instagram: @kathyyymm
          </li>

          <li>
          July
          Instagram: @kathyyymm
          </li>

          <li>
          August
          Instagram: @kathyyymm
          </li>

          <li>
          September
          Instagram: @kathyyymm
          </li>

          <li>
          October
          Instagram: @kathyyymm
          </li>

          <li>
          November
          Instagram: @kathyyymm
          </li>

          <li>
          December
          Instagram: @kathyyymm
          </li>
        </ul>

        <h2> Nails </h2>
        https://staticnails.com/
              
      </div>
    );
  }
  
export default Home;